import ApexCharts, { ApexOptions } from "apexcharts";
import { FC, useEffect, useRef } from "react";

const options: ApexOptions  = {
  tooltip: {
    enabled: true,
    x: {
      show: true,
    },
  },
  grid: {
    show: false,
    strokeDashArray: 4,
    padding: {
      left: 2,
      right: 2,
      top: -26
    },
  },
  series: [
    {
      name: "RAM",
      data: [3992, 2891, 3900, 2910, 3980, 4500],
      color: "#EFAA97",
    },
    {
      name: "CPU",
      data: [1492, 1880, 1082, 1185, 1282, 1984],
      color: "#E5BC88",
    },
  ],
  chart: {
    height: "100%",
    width: "100%",
    type: "area",
    fontFamily: "Inter, sans-serif",
    dropShadow: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: true
  },
  fill: {
    type: "gradient",
    gradient: {
      opacityFrom: 0.55,
      opacityTo: 0,
      shade: "#E5BC88",
      gradientToColors: ["#E5BC88"],
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 6,
  },
  xaxis: {
    categories: ['11:24:05', '11:24:10', '11:24:15', '11:24:20', '11:24:25', '11:24:30', '11:24:35'],
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
  },
};

export const LineChart: FC = () => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      const chart = new ApexCharts(ref.current, options);
      chart.render().then(() => {
        // this is a bug in apex charts that makes it render twice
        window.dispatchEvent(new Event('resize'));
      });
    }
  }, [ref]);

  return <div ref={ref}></div>
}
