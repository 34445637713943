import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import ReactFlow, {
  Edge,
  Node,
  OnEdgesChange,
  OnNodesChange,
  applyEdgeChanges,
  applyNodeChanges
} from "reactflow";
import "./app.css";
import { LinkEdge } from "./components/edges/link-edge";
import { DeploymentNode } from "./components/nodes/deployment-node";
import { DomainNode } from "./components/nodes/domain-node";
import { EnvironmentNode } from "./components/nodes/environment-node";
import { RepositoryNode } from "./components/nodes/repository-node";
import { Toasts } from "./components/toast";
import { SignupForm } from "./components/signup-form";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { TermsOfService } from "./pages/terms-of-service";
import { PrivacyPolicy } from "./pages/privacy-policy";
import { Icons } from "./components/icons";
import classNames from "classnames";
import { AnimatedButton } from "./components/button";

const { innerHeight: h, innerWidth: w, } = window;
const [mH, mW] = [h/2, w/2];

const initialNodes = [
  { id: "1", type: "DomainNode", position: { x: mW - 794, y: mH - 450 }, data: { name: "Payment Sub Domain" } },
  { id: "2", type: "DeploymentNode", position: { x: mW - 464, y: mH - 427 }, data: { expandHeight: true, name: "Payment Production", image: "clidey:payment-1.8.4" } },
  { id: "3", type: "DeploymentNode", position: { x: mW - 64, y: mH - 450 }, data: { expandWidth: true, name: "Auth Production", image: "clidey:auth-1.12.0" } },
  { id: "4", type: "DeploymentNode", position: { x: mW + 386, y: mH - 320 }, data: { expandWidth: true, expandHeight: true, name: "Frontend Service", image: "clidey:frontend-portal-1.1.1" } },
  { id: "5", type: "EnvironmentNode", position: { x: mW - 764, y: mH - 100 }, data: { name: "Payment secrets", keys: 4 } },
  { id: "6", type: "EnvironmentNode", position: { x: mW - 714, y: mH + 220 }, data: { expandWidth: true, name: "Global Secrets", keys: 24, } },
  { id: "7", type: "RepositoryNode", position: { x: mW - 20, y: mH + 250 }, data: { name: "Payment Development", editor: "VS Code" } },
  { id: "9", type: "DeploymentNode", position: { x: mW + 286, y: mH + 160 }, data: { expandWidth: true, name: "Clidey website", image: "clidey:website-1.32.0" } },
];
const initialEdges = [
  { id: "1->2", type: "LinkEdge", source: "1", target: "2" },
  { id: "2->3", type: "LinkEdge", source: "2", target: "3" },
  { id: "3->4", type: "LinkEdge", source: "3", target: "4" },
  { id: "5->2", type: "LinkEdge", source: "5", target: "2" },
  { id: "6->2", type: "LinkEdge", source: "6", target: "2" },
  { id: "2->7", type: "LinkEdge", source: "2", target: "7" },
];

const BackgroundGraph: FC = () => {
  const [nodes, setNodes] = useState<Node[]>(initialNodes);
  const [edges, setEdges] = useState<Edge[]>(initialEdges);

  const nodeTypes = useMemo(() => ({ RepositoryNode, DeploymentNode, DomainNode, EnvironmentNode }), []);
  const edgeTypes = useMemo(() => ({ LinkEdge }), []);

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [],
  );
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [],
  );

  return <ReactFlow className="blur-sm opacity-60 pointer-events-none" zoomOnScroll={false} nodes={nodes}
    edges={edges}
    onNodesChange={onNodesChange}
    onEdgesChange={onEdgesChange}
    nodeTypes={nodeTypes}
    edgeTypes={edgeTypes}
    proOptions={{
      hideAttribution: true
    }} />
}

const greetings = [
  <>Stay connected with us and receive the latest updates on <strong>Clidey</strong>'s exciting developments! <br /><br /> If you want to stay in the loop, simply share your email below.</>,
  <>Let's keep the conversation going! Sign up to receive updates on <strong>Clidey</strong>'s newest developments. <br /><br /> Share your email with us, and we'll make sure you stay informed.</>,
  <>Don't miss out on the latest from <strong>Clidey</strong>! Stay in touch and get updates on our latest developments.<br /><br /> Drop your email below if you'd like to hear from us.</>,
  <>We're eager to keep you in the know about <strong>Clidey</strong>'s progress!<br /><br /> If you're interested in receiving updates, please leave your email below, and we'll make sure to stay connected.</>,
  <>Stay informed about <strong>Clidey</strong>'s journey by joining our mailing list!<br /><br /> If you'd like to hear from us, share your email below, and we'll keep you updated on the latest developments.</>,
  <>Exciting things are happening at <strong>Clidey</strong>! Stay connected and receive updates by providing your email below.<br /><br /> We would love to stay in touch with you.</>,
  <>Your connection with <strong>Clidey</strong> doesn't have to end here! Sign up for updates on our latest developments by dropping your email below.<br /><br /> We look forward to staying in touch.</>,
  <>Be part of <strong>Clidey</strong>'s journey by staying informed about our latest developments!<br /><br /> If you'd like to hear back from us, drop your email below, and we'll ensure you receive regular updates.</>,
  <>Let's stay connected! Share your email with us, and we'll keep you updated on <strong>Clidey</strong>'s journey and exciting developments.<br /><br /> We look forward to staying in touch with you.</>,
  <>The <strong>Clidey</strong> community is growing, and we want you to be a part of it!<br /><br /> Stay in the loop by dropping your email below, and we'll make sure you receive updates on our latest developments.</>
];

const FeatureCard: FC<{ id: string, icon: ReactNode, title: string }> = ({ id, icon, title }) => {
  const handleClick = useCallback(() => {
    document.getElementById(id)?.scrollIntoView({
      behavior:"smooth",
      block: "end",
    });
  }, [id]);

  return (
    <div onClick={handleClick} className="text-gray-700 shadow-md rounded-lg w-[250px] bg-white h-fit px-8 py-4 text-md border border-gray-200 flex items-center transition-all cursor-pointer gap-1 hover:gap-3 hover:shadow-lg">
      {icon}
      {title}
    </div>
  )
}

const FeatureSpotlight: FC<{ id: string, title: string, description: string, link: string, showNext?: boolean, action?: ReactNode }> = ({ id, title, description, link, showNext, action }) => {
  return (<div id={id} className="h-[100vh] w-[100vw] flex gap-8 justify-center items-center relative max-md:flex-col max-md:gap-16 max-md:px-8">
    <div className="flex flex-col w-[450px] max-md:w-full gap-4">
      <div className="text-5xl max-md:text-4xl text-gray-700 font-bold">
        {title}
      </div>
      <div className="text-2xl max-md:text-lg text-gray-700 leading-9">
        {description}
      </div>
      {action}
    </div>
    <div className="text-gray-700 shadow-md rounded-2xl w-[550px] max-md:w-full h-fit bg-white text-lg border border-gray-200 flex items-center overflow-hidden">
      {
        link.endsWith(".mov")
        ? <video className="rounded-2xl" width="100%" height="100%" controls={true}>
            <source src={link} type="video/mp4"/>
            <source src={link} type="video/quicktime"/>
        </video>
        : <img src={link} className="w-full h-full" alt="feature" /> 
      }
    </div>
    <div className={classNames("rotate-90 absolute bottom-8 max-md:hidden", {
      "hidden": !showNext,
    })}>
      {Icons.DoubleRightArrow}
    </div>
  </div>);
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <>
      <Toasts />
      <div className="fixed h-[100vh] w-[100vw] top-0 left-0 flex flex-row gap-10 -z-10">
        <BackgroundGraph />
      </div>
      <div className="flex flex-col scroll-smooth overflow-y-scroll">
        <div className="relative h-[100vh] w-[100vw] flex flex-col justify-center items-center max-md:h-fit">
          <div className="flex min-md:flex-row gap-4 max-md:justify-center max-md:items-center max-md:flex-col-reverse max-md:mt-[25vh]">
            <div className="flex flex-col gap-4 justify-center max-md:mt-[15vh]">
              <div className="shadow-3xl font-bold text-gray-700 flex items-center gap-2">
                <img src="/logo.svg" className="w-14 h-14" alt="Clidey logo" />
                <div className="flex flex-col">
                  <span className="text-2xl text-[#BF853E]">Clidey</span> 
                  <span className="text-4xl">Spotlight</span>
                </div>
              </div>
              <FeatureCard icon={Icons.Dev.WhoDB.Default} title="WhoDB" id="whodb" />
              <FeatureCard icon={Icons.Deploy.Space.Default} title="Space" id="space" />
              <FeatureCard icon={Icons.CI_CD.Flow.Default} title="Flows" id="flows" />
              <FeatureCard icon={Icons.CI_CD.Hooks.Default} title="Hooks" id="hooks" />
              <FeatureCard icon={Icons.Logos.YouTube} title="YouTube Use-Case" id="youtube-use-case" />
            </div>
            <SignupForm />
          </div>
          <div className="mt-[5vh]">
            <AnimatedButton className="bg-[#ca6f1e] hover:bg-[#ca6f1e] text-white w-[200px] h-[40px]"
              labelClassName="text-white" iconClassName="stroke-white"
              icon={Icons.TopRightArrow} label="Checkout Blog" onClick={() => window.open("https://blog.clidey.com", "_blank")} />
          </div>
          <div className="rotate-90 absolute bottom-8 max-md:hidden">
            {Icons.DoubleRightArrow}
          </div>
        </div>
        <FeatureSpotlight id="whodb" title="WhoDB"
                          description="A powerful and user-friendly database management tool that combines the simplicity of Adminer with superior UX and performance. WhoDB is written in GoLang for optimal speed and efficiency and features interactive graphs for visualizing your entire database schema."
                          link="/images/whodb.png"
                          showNext={true} 
                          action={<div className="flex justify-end max-md:justify-center"><AnimatedButton className="bg-[#ca6f1e] hover:bg-[#ca6f1e] text-white w-[200px] h-[40px]" labelClassName="text-white" iconClassName="stroke-white" icon={Icons.TopRightArrow} label="Try today" onClick={() => window.open("https://github.com/clidey/whodb", "_blank")} /></div>} />
        <FeatureSpotlight id="space" title="Space"
                          description="Visualization is very important in an organisation. When you a join a new company, debugging an issue, or simply understanding data flow - visualization of infrastructure becomes mission critical. Clidey makes it extremely simple to visualize everything from Development to Deployment."
                          link="/videos/visualization.mov"
                          showNext={true} />
        <FeatureSpotlight id="flows" title="Flows"
                          description="It is not straightforward to containerize images. With Clidey, you can easily use one of our templates (create-react-app, NextJS, etc.) or your custom Dockerfile to containerize and push applications easily. You can further use Flows to run unit or E2E tests."
                          link="/videos/flows.mov"
                          showNext={true} />
        <FeatureSpotlight id="hooks" title="Hooks"
                          description="It is difficult to organise DevOps jobs. From running build jobs after a PR has merged to create a PR preview when the PR is open, Hooks make it extremely easy to automate logical workflows. Furthermore, you can automate management needs like sending email or slack message when build fails."
                          link="/videos/hooks.mov"
                          showNext={true} />
        <FeatureSpotlight id="youtube-use-case" title="YouTube Use-Case"
                          description="To test the boundaries of no-code, we deployed an entire open-source YouTube stack. This show cases how you can easily manage complicated infrastructure with minimal in-house resources."
                          link="/videos/youtube.mov" />
        <div className="w-[100vw] from-transparent to-gray-400 bg-gradient-to-b min-h-[400px] flex flex-col justify-center items-center gap-8 max-md:gap-16 pb-16">
          <div className="flex gap-8 max-md:flex-col max-md:items-center">
            <a href="mailto:support@clidey.com" className="flex gap-1 transition-all hover:scale-110 cursor-pointer">{Icons.Mail} Support Email </a>
            <a href="https://www.linkedin.com/company/clidey/" target="_blank" rel="noreferrer" className="flex gap-1 transition-all hover:scale-110 cursor-pointer">{Icons.Logos.LinkedIn} LinkedIn </a>
            <a href="https://www.facebook.com/clidey/" target="_blank" rel="noreferrer" className="flex gap-1 transition-all hover:scale-110 cursor-pointer">{Icons.Logos.Facebook} Facebook </a>
            <a href="https://www.instagram.com/clideyofficial/" target="_blank" rel="noreferrer" className="flex gap-1 transition-all hover:scale-110 cursor-pointer">{Icons.Logos.Instagram} Instagram </a>
          </div>
          <div className="flex gap-8 max-md:flex-col max-md:items-center">
            <a href="/privacy-policy" target="_blank" rel="noreferrer" className="flex gap-1 transition-all hover:scale-110 cursor-pointer items-center">Privacy Policy {Icons.TopRightArrow}</a>
            <a href="/terms-of-service" target="_blank" rel="noreferrer" className="flex gap-1 transition-all hover:scale-110 cursor-pointer items-center">Terms of Service {Icons.TopRightArrow}</a>
          </div>
        </div>
      </div>
    </>
  },
  {
    path: "/startup-show",
    element: <>
      <Toasts />
      <div className="fixed h-[100vh] w-[100vw] top-0 left-0 flex flex-row gap-10 opacity-50">
        <BackgroundGraph />
      </div>
      <div className="relative h-[100vh] w-[100vw] flex flex-col justify-center items-center">
        <SignupForm
          header={<div className="my-4 flex flex-row gap-2 justify-center items-center">
            <img alt="startup-show" src="/startup-show-logo.svg" className="h-auto w-40" />
            <div className="text-4xl">💕</div>
            <img alt="clidey" src="/logo.svg" className="h-16 w-auto" />
          </div>}
          title={<>It was lovely meeting you at <br /> <span className="text-[#ca6f1e]">The Startup Show</span>!</>}
          description={greetings[Math.floor(Math.random() * greetings.length)]} />
        </div>
    </>
  },
  {
    path: "/terms-of-service",
    element: <TermsOfService />
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}



export default App;
