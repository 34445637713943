import { FC } from 'react';
import {
  BaseEdge,
  getSimpleBezierPath
} from 'reactflow';

type LinkEdgeProps = {
  id: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
}

export const LinkEdge: FC<LinkEdgeProps> = (props) => {
  const [edgePath] = getSimpleBezierPath({
    sourceX: props.sourceX,
    sourceY: props.sourceY,
    targetX: props.targetX,
    targetY: props.targetY,
  });

  return (
      <BaseEdge id={props.id} path={edgePath} style={{
        stroke: "rgba(0,0,0,0.05)"
      }} />
  );
}