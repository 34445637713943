import { FC } from "react";
import { Card } from "../card";
import { Icons }  from "../icons";
import { Handle, Position } from "reactflow"
import { NodeProps } from "./node";

type EnvironmentNodeProps = NodeProps<{
  name: string;
  keys: number;
}>;

export const EnvironmentNode: FC<EnvironmentNodeProps> = (props) => {
  return <>
    
    <Handle type="source" position={Position.Top} style={{
      background: "rgba(0,0,0,0.1)"
    }} />
    <Handle type="target" position={Position.Left} style={{
      background: "rgba(0,0,0,0.1)"
    }} />
    <Card className="transition-all opacity-25" expandHeight={props.data.expandHeight} expandWidth={props.data.expandWidth}
      icon={{ component: Icons.Deploy.Environment.Default, className: "bg-indigo-500" }} tag={<div className="rounded-md bg-green-500 text-xs text-white py-1 px-2">
      Encrypted
    </div>}>
      <div className="flex flex-col">
        <div className="text-md font-semibold">{props.data.name}</div>
        <div className="text-xs">Total keys: {props.data.keys}</div>
      </div>
      <div className="flex flex-col">
        <div className="text-sm"><span className="font-semibold">PROVIDER_SECRET:</span> Hidden</div>
        <div className="text-sm"><span className="font-semibold">PROVIDER_NAME:</span> Hidden</div>
        <div className="text-sm"><span className="font-semibold">PROVIDER_EMAIL:</span> Hidden</div>
      </div>
    </Card>
  </>
}