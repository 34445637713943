import classNames from "classnames";
import { FC, ReactElement, cloneElement, useCallback, useState } from "react";
import { Icons } from "../components/icons";

type CardProps = {
  className?: string;
  icon: {
    component: ReactElement,
    className?: string,
  };
  tag?: ReactElement;
  children: ReactElement[];
  expandWidth?: boolean;
  expandHeight?: boolean;
}

export const Card: FC<CardProps> = (props) => {
  const [expandWidth, setExpandedWidth] = useState(props.expandWidth);
  const [expandHeight, setExpandedHeight] = useState(props.expandHeight);

  const handleToggleWidth = useCallback(() => setExpandedWidth(status => !status), []);
  const handleToggleHeight = useCallback(() => setExpandedHeight(status => !status), []);

  return (
    <div className={
      classNames("group/card h-[200px] w-[200px] rounded-3xl shadow-md border p-4 flex flex-col justify-between relative transition-all duration-300 bg-white", props.className, {
        "w-[400px]": expandWidth,
        "h-[400px]": expandHeight,
      })}>
      <div className="flex flex-col gap-2 flex-grow">
        <div className="flex justify-between items-start">
          <div className={classNames("h-[50px] w-[50px] rounded-lg flex justify-center items-center", props.icon.className)}>{cloneElement(props.icon.component, {
            className: "w-8 h-8 stroke-white",
          })}</div>
          {props.tag}
        </div>
        <div className={classNames("flex", {
          "flex-col gap-10": expandHeight && expandWidth,
          "flex-row": !(expandHeight && expandWidth),
        })}>
          <div className={classNames("flex", {
            "flex-col gap-20": expandHeight && !expandWidth,
            "flex-row": !expandHeight,
          })}>
            <div className="animate-fade min-w-[200px] transition-all">
              {props.children[0]}
            </div>
            <div className={classNames("pointer-events-none min-w-[200px] transition-all delay-500", {
              "animate-fade pointer-events-auto": (expandWidth || expandHeight),
              "hidden": !(expandWidth || expandHeight),
            })}>
              {props.children[1]}
            </div>
          </div>
          <div className={classNames("pointer-events-none transition-all delay-500", {
            "animate-fade pointer-events-auto": (expandWidth && expandHeight),
            "hidden": !(expandWidth && expandHeight),
          })}>
            {props.children[2]}
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <div className="rounded-lg border flex justify-center items-center text-xs px-2 py-1 cursor-pointer transition-all gap-1 hover:gap-2">
          <div className="text-xs">
            Explore
          </div>
          {cloneElement(Icons.RightArrow, {
            className: "w-4 h-4",
          })}
        </div>
        <div className="rounded-lg border flex justify-center items-center text-xs px-2 py-1 cursor-pointer transition-all gap-1 hover:gap-2">
          <div className="text-xs">
            Open
          </div>
          {cloneElement(Icons.RightArrow, {
            className: "w-4 h-4 -rotate-45",
          })}
        </div>
      </div>
      <div className="opacity-0 group-hover/card:opacity-100 transition-all">
        <div className="absolute -right-3 bottom-4 w-6 h-6 bg-white border rounded-full flex justify-center items-center transition-all cursor-pointer hover:scale-110" onClick={handleToggleWidth}>
          {cloneElement(Icons.DoubleRightArrow, {
            className: classNames("w-4 h-4 duration-300 stroke-gray-500", {
              stroke: "rgba(0,0,0,0.1)",
              "-rotate-180": expandWidth,
            }),
          })}
        </div>
        <div className="absolute right-4 -bottom-3 w-6 h-6 bg-white border rounded-full flex justify-center items-center transition-all cursor-pointer rotate-90 hover:scale-110" onClick={handleToggleHeight}>
          {cloneElement(Icons.DoubleRightArrow, {
            className: classNames("w-4 h-4 duration-300 stroke-gray-500", {
              "-rotate-180": expandHeight,
            }),
          })}
        </div>
      </div>
    </div>
  )
}