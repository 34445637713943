import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 } from "uuid";

export type IToast = {
  id: string;
  message: string;
  icon?: React.ReactElement;
  timeout?: number;
  intent?: "default" | "success" | "error" | "info" | "warning";
}

type IGlobalState = {
  toasts: (IToast & { id: string })[];
}

const initialState: IGlobalState = {
  toasts: [],
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<Pick<IToast, Exclude<keyof IToast, "id">>>) => {
      const id = v4();
      state.toasts.push({
        id,
        ...action.payload,
      });
    },
    removeToast: (state, action: PayloadAction<{ id: string }>) => {
      state.toasts = state.toasts.filter(toast => toast.id !== action.payload.id);
    },
  },
});

export const GlobalActions = globalSlice.actions;
export const globalReducers = globalSlice.reducer;