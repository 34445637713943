import jsonp from "jsonp";
import { ChangeEvent, FC, KeyboardEvent, ReactElement, cloneElement, useCallback, useState } from "react";
import { GlobalActions } from "../store/global";
import { useAppDispatch } from "../store/hooks";
import { Icons } from "./icons";

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

type ISignupFormProps = {
  header?: ReactElement;
  title?: ReactElement;
  description?: ReactElement;
}

export const SignupForm: FC<ISignupFormProps> = (props) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | null>(null);
  const [value, setValue] = useState("");
  const handleSubmit = useCallback(() => {
    if (!validateEmail(value)) {
      setError("Email is not valid");
      return;
    }
    jsonp(`https://clidey.us21.list-manage.com/subscribe/post-json?u=11174af68a4e9c1c5794959ad&id=f18b336972&c=&f_id=0053e4e6f0&b_11174af68a4e9c1c5794959ad_f18b336972=&` + new URLSearchParams({
      EMAIL: value,
    }), {
      param: "c",
    }, (err, data) => {
      if (err != null) {
        setError(data.msg);
        return;
      }
      dispatch(GlobalActions.addToast({
        message: "Thank you for your interest!",
        intent: "success",
      }));
      setError(null);
      setValue("");
    });
  }, [value, dispatch]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    setValue(e.target.value);
  }, []);

  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      return handleSubmit();
    }
  }, [handleSubmit]);

  return (
    <div className="flex w-[350px] rounded-2xl shadow-lg z-10 bg-white border border-gray-200 py-8">
      <div className="flex flex-col px-8 items-center justify-center flex-grow">
        <div>
          {props.header}
          <label className="block text-gray-700 text-3xl font-semibold">
            {props.title ?? <div className="text-2xl"><span className="text-3xl">Early</span> <br /> Access is here 🎉!</div>}
          </label>
          <div className="text-md mt-8 mb-2">
            {props.description ?? <>The long wait has come to an end. <strong>Clidey</strong> is now available for early access! <br /> <br /> Pop in your email down below and we will be in touch.</>}
          </div>
          <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-4" id="email" type="text" placeholder="Email"
            value={value} onChange={handleChange} onKeyDown={handleKeyDown} />
        </div>
        <div className="text-xs text-red-700 w-full mt-2">
          {error}
        </div>
        <div className="flex justify-end w-full mt-2">
          <div className="flex items-center justify-between" onClick={handleSubmit}>
            <div className="rounded-lg border flex justify-center items-center px-2 py-1 cursor-pointer transition-all gap-1 hover:gap-3">
              <div className="text-sm text-gray-600">
                Submit
              </div>
              {cloneElement(Icons.CheckCircle, {
                className: "w-6 h-6 stroke-gray-600",
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}