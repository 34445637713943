import { configureStore } from '@reduxjs/toolkit';
import { globalReducers } from './global';

export const reduxStore = configureStore({
  reducer: {
    global: globalReducers,
  },
});


export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;