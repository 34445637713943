import { FC } from "react";
import { Card } from "../card";
import { Icons }  from "../icons";
import { Handle, Position } from "reactflow"
import { NodeProps } from "./node";


export const DomainNode: FC<NodeProps> = (props) => {
  return <>
    <Handle type="source" position={Position.Right} style={{
      background: "rgba(0,0,0,0.1)"
    }} />
    <Handle type="target" position={Position.Left} style={{
      background: "rgba(0,0,0,0.1)"
    }} />
    <Card className="transition-all opacity-25" expandHeight={props.data.expandHeight} expandWidth={props.data.expandWidth}
      icon={{ component: Icons.Deploy.Domain.Default, className: "bg-amber-500" }} tag={<div className="rounded-md bg-green-500 text-xs text-white py-1 px-2">
      Published
    </div>}>
      <div className="flex flex-col">
        <div className="text-md font-semibold">Payment Sub Domain</div>
        <div className="text-xs">Domain: payment.clidey.com</div>
      </div>
      <div className="flex flex-col">
        <div className="text-sm"><span className="font-semibold">Expiry:</span> 3 months</div>
        <div className="text-sm"><span className="font-semibold">Type:</span> DNS Based</div>
        <div className="text-sm"><span className="font-semibold">Issuer:</span> LetsEncrypt</div>
      </div>
    </Card>
  </>
}